<template>
  
</template>

<script>
export default {
  created(){
    var name=this.$route.params.routeName;
    this.$router.replace({
      name:name,
      params:{
        ...this.$route.params
      },
      query:{
        ...this.$route.query
      }
    });
  }
}
</script>